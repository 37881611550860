import { React, useState, useEffect } from "react";
// import logo from "../logo.svg";
import "./Account.css";

import Backend from "../../Backend";
import play from "../../Images/play.png";

import logo from "../../Images/logo.png";
import ruppee from "../../Images/rupee.png";
import database from "../../Images/database.png";
import profile from "../../Images/profile-user.png";
import bank from "../../Images/bank.png";
import money from "../../Images/money.png";
import stock from "../../Images/stock-market.png";
import gold from "../../Images/gold-ingot.png";
import candle from "../../Images/candlestick.png";

import moment from "moment";
import { NewTheme } from "../../Theme/Theme";

const backend = new Backend();

const Account = () => {
  const logoutHandler = () => {
    localStorage.removeItem("IsLoggedIn");
    localStorage.removeItem("token");
    localStorage.removeItem("rules");
    localStorage.removeItem("server");
    localStorage.removeItem("client");
    window.location = "/";
  };
  const [loading, setLoading] = useState(false);
  const [profiledata, setProfiledata] = useState([]);
  const [brokerages, setBrokerages] = useState([]);
  const [multiplier, setMultiplier] = useState([]);

  useEffect(() => {
    loadprofile();
  }, []);
  const loadprofile = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };

    try {
      backend.loadprofile(data).then((r) => {
        setTimeout(() => {
          setLoading(false);
          // refRBSheet.current.open();
        }, 500);

        if (r.error == "False") {
          setProfiledata(r.data);
          setBrokerages(JSON.parse(r.data.brokerages));
          setMultiplier(JSON.parse(r.data.multiplier));
        }
      });
    } catch {}
  };

  return (
    <div
      className="Account_Container"
      style={{ background: "white", paddingTop: 10 }}
    >
      <div
        className="top_bar"
        style={{
          position: "fixed",
          background: "white",
          top: 0,
          paddingTop: 30,
          width: "100%",
          paddingBottom: 30,
        }}
        onClick={() => (window.location = "/profile")}
      >
        <img src={play} className="top_back_icon" alt="" />
        <p className="title_text">Account Details</p>
      </div>

      <div className="details_box" style={{ paddingBottom: 10, marginTop: 70 }}>
        <img
          style={{
            height: 300,
            width: 300,
          }}
          src={
            "https://img.dreamtrade.biz/" +
            localStorage.getItem("server").toLowerCase() +
            ".gif?id=" +
            new Date()
          }
          alt=""
          className="list_icon"
        />
        <p className="detail_title">Summary</p>
        <div className="detail_item">
          <img src={ruppee} alt="" className="detail_icon" />
          <p className="detail_text">Balance</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
          <p>{parseFloat(profiledata.deposit).toFixed(2)}</p>
        </div>
        <div className="detail_item">
          <img src={database} alt="" className="detail_icon" />
          <p className="detail_text">Server</p>
          <p>{profiledata.server}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_divider" />
        <p className="detail_title">Account Details</p>
        <div className="detail_item">
          <img src={profile} alt="" className="detail_icon" />
          <p className="detail_text">A/C Date</p>
          <p>{moment(profiledata.date_created).format("DD-MMM-YY")}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_item">
          <img src={bank} alt="" className="detail_icon" />
          <p className="detail_text">Total Limit</p>
          <p>{parseFloat(profiledata.total_limit).toFixed(0)}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_item">
          <img src={money} alt="" className="detail_icon" />
          <p className="detail_text">Margin Amount</p>
          <p>{parseFloat(profiledata.total_limit).toFixed(0)}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>

        <div className="detail_divider" />

        <p className="detail_title">Brokerage Details</p>
        <div className="detail_item">
          <img src={stock} alt="" className="detail_icon" />
          <p className="detail_text">Future</p>
          <p>{brokerages.fut_brokerage * 100000 + "/CR"}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_item">
          <img src={gold} alt="" className="detail_icon" />
          <p className="detail_text">MCX</p>
          <p>{brokerages.mcx_brokerage + "/LOT"}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_item">
          <img src={candle} alt="" className="detail_icon" />
          <p className="detail_text">Options</p>
          <p>{brokerages.fo_brokerage + "/LOT"}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>

        <div className="detail_divider" />

        <p className="detail_title">Leverage Details</p>
        <div className="detail_item">
          <img src={stock} alt="" className="detail_icon" />
          <p className="detail_text">Future</p>
          <p>{"X" + multiplier.fut_multiplier}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_item">
          <img src={gold} alt="" className="detail_icon" />
          <p className="detail_text">MCX</p>
          <p>{"X" + multiplier.mcx_multiplier}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>
        <div className="detail_item">
          <img src={candle} alt="" className="detail_icon" />
          <p className="detail_text">Options</p>
          <p>{"X" + multiplier.fo_multiplier}</p>
          {/* <img src={logo} alt="" className="detail_next_icon" /> */}
        </div>

        {/* <div className="detail_divider"/> */}
      </div>
      <div style={{ height: 100 }}></div>
    </div>
  );
};

export default Account;
