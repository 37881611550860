import { React, useEffect, useState } from "react";

import "./Search.css";
import logo from "../../logo.svg";
import play from "../../Images/play.png";

import Backend from "../../Backend";
import moment from "moment";

const backend = new Backend();

const Searchitem = (props) => {
  const i = props.item;
  const [loading, setLoading] = useState(false);
  const [find, SetFind] = useState(0);
  const addscript = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      script: i,
      index: localStorage.getItem("selectTab"),
    };

    backend.addscript(data).then((r) => {
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);
      if (r.error == "False") {
        SetFind(1);
        // setSearchData(r.data);
      }
    });
  };

  const removescript = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      removeid: i.id,
    };

    backend.removescript(data).then((r) => {
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);

      if (r.error == "False") {
        SetFind(0);

        // setSearchData(r.data);
      }
    });
  };
  return (
    <div
      className="search_item"
      id={props.item.id}
      value={props.item}
      onClick={(e) => {
        // addSearchHandler(e, index);
      }}
    >
      {/* {updateValue(item.id)} */}
      <p className="char_coin">{props.item.name.charAt(0)}</p>
      <p className="script_text">{props.item.name}</p>
      <p className="expiry_text">
        {moment(props.item.expiry_date).format("DD-MMM")}
      </p>
      <img
        src={logo}
        alt=""
        onClick={() => {
          addscript();
        }}
        className="p_m_icon"
      />
      {/* {idList.includes(parseInt(item.id))  ? "present" : "Not"} */}
      {/* <p>{isPresent ? "Present" : "Not"}</p> */}
    </div>
  );
};

export default Searchitem;
