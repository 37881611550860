import { useWindowSize } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import { NewTheme } from "./Theme/Theme";
import Backend from "./Backend";

const backend = new Backend();

export default function Rules(props) {
  const [rules, setSetrules] = useState([]);
  const { height, width } = useWindowSize();
  const [visible, setVisible] = useState(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadrules();

    setVisible(sessionStorage.getItem("rulesheet") == "1" ? false : true);
  }, [sessionStorage.getItem("rulesheet")]);

  const loadrules = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };

    backend
      .load_rules(data)
      .then((r) => {
        setTimeout(() => {
          setLoading(false);
          // refRBSheet.current.open();
        }, 500);
        if (r.error == "False") {
          setSetrules(r.rules);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          // refRBSheet.current.open();
        }, 500);
        console.log(err);
      });
  };

  return sessionStorage.getItem("rulesheet") != "1" ? (
    <div
      style={{
        height: height,
        background: "white",
        width: width,
        position: "fixed",
        top: 0,
        zIndex: 9999999999000,
      }}
    >
      <div
        style={{
          height: 40,
          background: NewTheme.MainColor,
          display: "flex",
          alignItems: "center",
          paddingLeft: 10,
          fontWeight: "bold",
          color: "white",
        }}
      >
        TERMS AND CONDITIONS
      </div>
      <div
        style={{
          height: height - 90,
          overflowY: "scroll",
          background: "white",
          padding: 5,
        }}
      >
        {rules?.map((i, t) => (
          <div
            style={{
              display: "flex",
              borderStyle: "solid",
              padding: 5,
              borderWidth: 1,
              borderRadius: 5,
              borderColor: "gray",
              marginTop: 5,
            }}
          >
            <div
              style={{
                minWidth: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {t + 1}.
            </div>
            <div>{i.rules}</div>
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          {
            sessionStorage.setItem("rulesheet", "1");
            setVisible(false);
          }
        }}
        style={{
          height: 50,
          width: "100%",
          backgroundColor: NewTheme.MainColor,
          color: "white",
          borderWidth: 0,
        }}
      >
        I AGREE TERMS AND CONDITIONS
      </button>
    </div>
  ) : null;
}
