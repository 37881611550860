import React, { useEffect, useState } from "react";
import noti_icon from "../../Images/alarm.png";
import { NewTheme } from "../../Theme/Theme";
import Backend from "../../Backend";
const backend = new Backend();

export default function Newsicon() {
  const [news, setNews] = useState([]);
  const [count, SetCount] = useState(0);

  useEffect(() => {
    loadnews();
  }, []);

  const gotonews = () => {
    if (news.length > 0) {
      localStorage.setItem("news", JSON.stringify(news));
      window.location = "/news";
    } else {
      window.alert("No News At This Time");
    }
  };

  const loadnews = () => {
    // setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };

    backend.loadnews(data).then(async (r) => {
      if (r.error == "False") {
        setNews(r.data);
        const newdata = localStorage.getItem("setid");
        const finaldata = await r.data.filter((x) => x.id > newdata);
        SetCount(finaldata.length);
      }
    });
  };

  return (
    <div
      style={{
        width: 40,
        height: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
      onClick={gotonews}
    >
      <img src={noti_icon} style={{ height: 25, width: 25 }} />
      {count > 0 ? (
        <div
          style={{
            background: NewTheme.MainColor,
            width: 20,
            height: 20,
            top: 5,
            right: 0,
            borderRadius: 50,
            position: "absolute",
            color: "white",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          {count}
        </div>
      ) : null}
    </div>
  );
}
