import {
  React,
  useState,
  useEffect,
  useCallback,
  Component,
  useContext,
} from "react";
import "./Position.css";
import logo from "../../logo.svg";
import moment from "moment";

import Backend from "../../Backend";

import Positionitem from "./Positionitem";
import { useWindowSize } from "@uidotdev/usehooks";
import TopCardPos from "./TopCardPos";

const backend = new Backend();

const Position = () => {
  const { height } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState([]);
  const [position_filter, setPosition_Filter] = useState([]);
  const [booked, setBooked] = useState(0);
  const [brokerage, setBrokerage] = useState(0);
  const [balance, setBalance] = useState(0);
  const [limit, setlimit] = useState(0);
  const [multiplier, setmultiplier] = useState(0);

  const [profileSelected, setProfileSelected] = useState("");

  useEffect(() => {
    loadPosition();
  }, []);

  const loadPosition = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };
    // props.clear_pf();
    // setBooked(0);
    backend.loadPosition(data).then((r) => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      if (r.error == "False") {
        // props.clear_pf();
        setPosition(r.data);
        setPosition_Filter(r.data);
        setBalance(r.balance);
        setBooked(r.booked);
        // setBrokerage(r.brokerage);
        setlimit(r.blocked_limit);
        setmultiplier(r.multiplier);
      } else {
        alert(r.message);
      }
    });
  };

  const handleKeyPress = useCallback((event) => {
    if (event.key === "ArrowUp") {
      setProfileSelected(position[position.indexOf(profileSelected) - 1]);
    }
    if (event.key === "ArrowDown") {
      setProfileSelected(position[position.indexOf(profileSelected) + 1]);
    }
  });
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  const onclickevent = useCallback((item) => {
    setProfileSelected(item);
  });

  const PosClose = (data) => {
    if (!loading) {
      if (data.qty <= 0) {
        alert("Qty Require Greater Then 0");
      } else {
        setLoading(true);
        let sendData = {
          // sv: localStorage.getItem("server"),
          // id: localStorage.getItem("client"),
          // jwt: localStorage.getItem("token"),
          m_code: data.script_id,
          order: data.qty,
          // order: qtypos,
          ud: data.buysell == 1 ? "0" : "1",
          segi: data.script_type,
          token: localStorage.getItem("token"),
        };

        backend
          .sqoffposition(sendData)
          .then((r) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            if (r.success == "True") {
              // load_position_overview();
              loadPosition();
              alert(r.msg);
            } else {
              alert(r.msg);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
            console.log(err);
            // Alert.alert('', err);
          });
      }
    }
  };

  const sqoffall = () => {
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
    };
    var check = window.confirm("ARE YOU SURE SQ OFF ALL POSITION ?");
    if (!check) {
      return false;
    }

    if (!loading) {
      // setLoading(true);
      backend.sq_off_position(data).then((r) => {
        setTimeout(() => {
          setLoading(false);
        }, 500);

        if (r.error == "False") {
          loadPosition();
          window.alert(r.message);
          // props.clear_pf();
          // setPosition(r.data);
          // setPosition_Filter(r.data);
          // setBalance(r.balance);
          // setBooked(r.booked);
          // // setBrokerage(r.brokerage);
          // setlimit(r.limit);
          // setmultiplier(r.multiplier);
        } else {
          window.alert(r.message);
          localStorage.clear();
          window.location = "/";
        }
      });
    }
  };

  return (
    <div
      className="Position_Container"
      style={{ background: "rgba(240,240,240,0.6)" }}
    >
      <div className="back_color_box"></div>
      <TopCardPos
        sqoffall={sqoffall}
        booked={booked}
        balance={balance}
        limit={limit}
        multiplier={multiplier}
        loadPosition={loadPosition}
        brokerage={brokerage}
        position={position}
        loading={loading}
      />

      <div
        style={{
          width: "100%",
          height: height - 175,
          overflowY: "scroll",
          marginTop: 20,
        }}
      >
        {position &&
          position.map((item, index) => (
            <Positionitem
              item={item}
              index={index}
              length={position.length - 1}
              total={position.length}
            />
          ))}
        <div style={{ height: 200 }}></div>
      </div>
    </div>
  );
};

export default Position;
