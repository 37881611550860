import React, { useContext, useEffect, useState } from "react";
import { NewTheme } from "../../Theme/Theme";
import Backend from "../../Backend";
import moment from "moment";
import SocketContect from "../../SocketContext";
const backend = new Backend();

export default function PendingSheet(props) {
  const i = props.data;
  const socket = useContext(SocketContect);

  const [qty, setqty] = useState();
  const [oldqty, setOldqty] = useState(props.data.qty);
  //
  const [price, setPrice] = useState(0);

  const [oldprice, setOldprice] = useState(props.data.price);
  // ;
  const [loading, setLoading] = useState(false);

  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [ltp, setltp] = useState(0);
  const [time, setTime] = useState("");

  const trade = (msg) => {
    if (msg == null) {
    } else {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        setltp(msg.LTP);
      }
      if (time !== msg.Timestamp) {
        setTime(msg.Timestamp);
      }
    }
  };

  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
    }

    if (time !== msg.Timestamp) {
      setTime(msg.Time);
    }
  };

  useEffect(() => {
    setPrice(parseFloat(props.data.price));
    setqty(parseFloat(props.data.qty));
    socket.emit("giverate", props.data.script_id);

    socket.on("trade" + props.data.script_id, trade);

    socket.on("bidask" + props.data.script_id, bidask);

    return () => {
      socket.off("trade" + props.data.script_id, trade);

      socket.off("bidask" + props.data.script_id, bidask);
    };
  }, []);

  const edit_peding = () => {
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      pending_id: props.data.id,
      qty: qty,
      price: price,
    };

    var check = window.confirm("ARE YOU SURE MODIFY PENDING");
    if (!check) {
      return false;
    }

    backend.edit_pending(data).then((r) => {
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);

      if (r.error == "False") {
        window.alert(r.message);
        props.closesheet();
      } else {
        window.alert(r.message);
      }
    });
  };

  const canclelimit = () => {
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      pending_id: props.data.id,
    };

    var check = window.confirm("ARE YOU SURE CANCEL PENDING");
    if (!check) {
      return false;
    }

    backend.cancel_pending(data).then((r) => {
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);

      if (r.error == "False") {
        window.alert(r.message);
        props.closesheet();
      } else {
        window.alert(r.message);
      }
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <div
          style={{
            color: NewTheme.MainColor,
            fontWeight: "bold",
            fontSize: 17,
          }}
        >
          Pending Limit
        </div>
        <button
          onClick={canclelimit}
          style={{
            fontSize: 15,
            color: NewTheme.MainColor,
            // background: "white",
            // borderStyle: "solid",
            // borderWidth: 1,
            // borderColor: NewTheme.MainColor,
            // padding: 10,
          }}
        >
          Cancle Limit
        </button>
      </div>
      <div style={{ borderStyle: "solid", borderWidth: 1, marginTop: 10 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 5,
            paddingBottom: 0,
          }}
        >
          <div>SYMBOL</div>
          <div style={{ display: "flex", fontWeight: "bold" }}>
            {i.symbol + " "}
            <div style={{ fontSize: 11, paddingLeft: 5, color: "gray" }}>
              {moment(i.expiry_date).format("DD-MMM")}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 5,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <div>Qty</div>
          <div style={{ display: "flex", fontWeight: "bold" }}>{i.qty}</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 5,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <div>Price</div>
          <div style={{ display: "flex", fontWeight: "bold" }}>{i.price}</div>
        </div>
      </div>
      <div
        style={{
          borderStyle: "solid",
          borderWidth: 1,
          marginTop: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>High</div>
          <div>{high2}</div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>LOW</div>
          <div>{low2}</div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>BID</div>
          <div>{bid}</div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>ASK</div>
          <div>{ask}</div>
        </div>
      </div>
      <div
        style={{ color: NewTheme.MainColor, fontWeight: "bold", marginTop: 5 }}
      >
        Modify Limit
      </div>
      <div style={{ position: "relative" }}>
        <input
          placeholder="INSERT NEW QTY"
          style={{
            width: "100%",
            padding: 10,
            marginTop: 5,
            fontSize: 16,
          }}
          onChange={(e) => setqty(e.target.value)}
        />
        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            fontWeight: "bold",
            color: "gray",
          }}
        >
          {i.qty}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <input
          placeholder="INSERT NEW PRICE"
          style={{
            width: "100%",
            padding: 10,
            marginTop: 5,
            fontSize: 16,
          }}
          onChange={(e) => setPrice(e.target.value)}
        />
        <div
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            fontWeight: "bold",
            color: "gray",
          }}
        >
          {i.price}
        </div>
      </div>
      <div>
        <button
          onClick={edit_peding}
          style={{
            width: "100%",
            marginTop: 10,
            fontSize: 17,
            height: 40,
            color: NewTheme.MainColor,
          }}
        >
          Modify Limit
        </button>
      </div>
    </div>
  );
}
