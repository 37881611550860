import { React, useEffect, useState } from "react";

import "./Search.css";
import logo from "../../logo.svg";
import play from "../../Images/play.png";

import Backend from "../../Backend";
import moment from "moment";
import Searchitem from "./Searchitem";
import { InputText } from "primereact/inputtext";

import { NewTheme } from "../../Theme/Theme";
import Searchcard from "./Searchcard";
import Searchcard2 from "./SearchCard2";
const backend = new Backend();

const Search = () => {
  const [search, setSearch] = useState("");
  const [wacthlist, SetWatchlist] = useState(
    JSON.parse(localStorage.getItem("watchlist"))
  );
  const [searchList, setSearchList] = useState([]);
  const [searchfoList, setSearchfoList] = useState([]);
  const [searchfutList, setSearchfutList] = useState([]);
  const [searchmcxList, setSearchmcxList] = useState([]);
  const [idList, setIdList] = useState([]);

  const [list, setList] = useState(
    typeof localStorage.localSearch === "undefined"
      ? []
      : JSON.parse(localStorage.localSearch)
  );

  const [selected, setSelected] = useState(list[0]);

  const [searchSelected, setSearchSelected] = useState("");

  var a = [];

  const Search_Script = (e) => {
    // setTextValue(e).tar;

    setSearch(e.target.value);

    if (search.length > 1) {
      let data = {
        sv: localStorage.getItem("server"),
        id: localStorage.getItem("client"),
        jwt: localStorage.getItem("token"),
        filter: e.target.value,
      };

      backend.searchscript(data).then((r) => {
        if (r.error == "False") {
          setSearchfutList(r.data.fut);
          setSearchmcxList(r.data.mcx);
          setSearchfoList(r.data.fo);
          // setSearchData(r.data);
          // setSearchList(r.datas);
          // setSearchSelected(r.datas[0].id);
        } else {
          // window.alert(r.message);
        }
      });
    } else {
      setSearchfutList([]);
      setSearchmcxList([]);
      setSearchfoList([]);
    }
  };

  const [isPresent, setIsPresent] = useState(false);
  const updateValue = (id) => {
    let a = idList.indexOf(parseInt(id));
    setIsPresent(a);
  };

  return (
    <div className="Search_Container">
      <div
        style={{
          height: 50,
          display: "flex",
          paddingRight: 10,
          boxSizing: "border-box",
          alignItems: "center",
          paddingLeft: 5,
          width: "100%",
          borderStyle: "solid",
          borderWidth: 0,
          borderBottomWidth: 1,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            height: 40,
            borderRadius: 5,
            borderColor: "gray",
            // boxSizing: "border-box",
            // marginLeft: 5,
            // paddingLeft: 10,
            // paddingRight: 10,
          }}
        >
          {/* <img
              src={search_icon}
              alt=""
              className="search_icon"
              style={{ height: 20, width: 20 }}
            /> */}
          <img
            onClick={() => (window.location = "/")}
            src={play}
            style={{
              height: 20,
              width: 20,
              marginLeft: 10,
              marginRight: 10,
              transform: "rotate(180deg",
            }}
          />

          <InputText
            value={search}
            onChange={Search_Script}
            placeholder="SEARCH SYMBOL"
            style={{
              height: 35,
              borderWidth: 0,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          />
        </div>
      </div>

      {searchmcxList.length > 0 ||
      searchfutList.length > 0 ||
      searchfoList.length > 0
        ? null
        : wacthlist.map((i, t) => <Searchcard key={i.script_id} data={i} />)}

      <div className="data_table">
        {/* {searchList &&
          searchList.map((item, index) => (
            <Searchitem item={item} index={index} />
          ))} */}

        {searchfutList.length > 0 ? (
          <p
            className="partition"
            style={{
              background: "unset",
              color: NewTheme.MainColor,
              padding: 5,
            }}
          >
            FUT
          </p>
        ) : null}
        {searchfutList &&
          searchfutList.map((item, index) => (
            <Searchcard2 key={item.script_id} data={item} index={index} />
          ))}

        {searchmcxList.length > 0 ? (
          <p
            className="partition"
            style={{
              background: "unset",
              color: NewTheme.MainColor,
              padding: 5,
            }}
          >
            MCX
          </p>
        ) : null}
        {searchmcxList &&
          searchmcxList.map((item, index) => (
            <Searchcard2 key={item.script_id} data={item} index={index} />
          ))}

        {searchfoList.length > 0 ? (
          <p
            className="partition"
            style={{
              background: "unset",
              color: NewTheme.MainColor,
              padding: 5,
            }}
          >
            OPTION
          </p>
        ) : null}
        {searchfoList &&
          searchfoList.map((item, index) => (
            <Searchcard2 key={item.script_id} data={item} index={index} />
          ))}
      </div>
    </div>
  );
};

export default Search;
